import React from 'react';
import './HeroSection.css';
import heroImage from '../../assets/heroImage.png';

const HeroSection = () => {
    return (
        <header className="plantao-page-hero-section">
            <div className="plantao-page-hero-content">
                <h1>Organize Seus Plantões e Aumente a Produtividade em Minutos</h1>
                <p>Transforme a forma como você organiza seus plantões, gerencia atendimentos e acompanha seus recebíveis com o Plantão Fácil. A solução completa para médicos que buscam eficiência e simplicidade.</p>
                <nav aria-label="Primary actions">
                    <a href="https://plantao.prontuariofacil.com.br/cadastrar" className="plantao-page-hero-button" id="botao-teste-gratis" role="button">
                        Teste Grátis por 14 Dias
                    </a>
                    <a href="https://plantao.prontuariofacil.com.br/login" className="plantao-page-hero-button" role="button">
                        Faça Login
                    </a>
                </nav>
            </div>
            <div className="plantao-page-hero-media">
                <img
                    src={heroImage}
                    alt="Imagem ilustrativa do Plantão Fácil"
                    loading="lazy"
                    className="plantao-page-hero-image"
                />
            </div>
        </header>
    );
};

export default HeroSection;
